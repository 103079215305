

<div class="d-flex vh-100 justify-content-center align-items-center">
	<div class="p-2 flex-grow">
	  <div class="smallContainer border shadow rounded">
		<div class="row g-0">
		  <div class="col-sm-6 col-xs-12 d-none d-sm-block position-relative" id='leftCol'>
			<img src="../../../assets/images/voiture.png" />
			<div id="pt-5 text-end w-100" class="position-absolute end-0 top-0">
			</div>
   
		  </div>
		  <div class="col-sm-6 col-xs-12">
			<div class="d-flex flex-column" style="height:100%">
   
			  <div class="text-center d-sm-none d-xs-block">
				
			  </div>
   
			  <div class="my-auto p-5">
				<div class="text-center">
				  <div>
					<img src="../../../assets/images/marketo.png" height="72" />
				  </div>
				  <h3  class="h3 pb-3" style="font-weight: bold;">Marketo</h3>

				 	 <h6 style="font-family: Georgia, 'Times New Roman', Times, serif;">Veuillez vous connecter</h6>
				</div>
				<form [formGroup]="loginForm" *ngIf="!newUser"> 
				  <div class="position-relative my-3 inputGroup text-center">
					<label style="padding-right: 210px;" class="col-form-label pt-0">Adresse E-mail</label>

					<input class="form-control" formControlName="email" type="email" required="" maxlength="30" placeholder="votreadresse@email.com">
				</div>
				<div class="form-group">
					<label class="col-form-label">Mot de passe</label>
					<div class="row">
						<div class="col-md-10 input-group">
							<input class="form-control " type="{{getInputType()}}" formControlName="password" required="" maxlength="30" placeholder="Entrez votre mot de passe">
							<div class="input-group-append" (click)="tooglePasswordView()">

								<div class="input-group-text">
									<span class="{{getIconValue()}}"></span>
								</div>
							</div>
			  
						</div>
					</div>
				</div>
					<!-- <a class="linkFlare" href="#"><small>Mot de passe oublié?</small></a> -->
					<div class="form-group row my-3 " style="width: 100%; margin-left: 1px;" >
						<button *ngIf="!isLoading" class="btn btn-primary btn-block btn-lg"
							[class.loader--text]="authService.showLoader"
							[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
							type="submit"><span>{{ authService.showLoader ? '' : 'Connexion' }}</span>
						</button>
					</div>
					<div class="form-group row mt-3 mb-0" *ngIf = "isLoading === true">
						<button class="btn btn-primary" type="button" disabled>
							<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
							Chargement...
						  </button>
					</div>
				</form>
			  </div>
			  <p>version 0.0.53</p>
			</div>
		  </div>
		</div>
	  </div>
	</div>
	
  </div>

